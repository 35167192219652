/** @format */

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '../store';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: {
			name: 'apply',
			query: {
				product_id: '4372617600381042827',
			},
		},
	},
	{
		path: '/turnover',
		name: 'turnover',
		component: () => import('../views/turnover.vue'),
	},
	{
		path: '/apply',
		name: 'apply',
		meta: {
			type: 'login',
		},
		component: () => import('../views/login.vue'),
	},
	{
		path: '/administrator',
		name: 'administrator',
		meta: {
			type: 'apply',
		},
		component: () => import('../views/administrator.vue'),
	},
	{
		path: '/company-msg',
		name: 'companyMsg',
		meta: {
			type: 'apply',
		},
		component: () => import('../views/company-msg.vue'),
	},
	{
		path: '/financial',
		name: 'financial',
		meta: {
			type: 'apply',
		},
		component: () => import('../views/financial.vue'),
	},
	{
		path: '/apply-steps',
		name: 'applySteps',
		component: () => import('../views/apply-steps.vue'),
	},
	{
		path: '/agree-detail',
		name: 'agreeDetail',
		meta: {
			type: 'login',
		},
		component: () => import('../views/agree-detail.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name == 'apply' && !to.query.product_id) {
		next({
			path: to.path,
			query: {
				product_id: '4372617600381042827',
			},
		});
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	let step = 0;
	switch (to.name) {
		case 'administrator':
			step = 1;
			break;
		case 'companyMsg':
			step = 2;
			break;
		case 'financial':
			step = 3;
			break;

		default:
			step = 0;
			break;
	}
	store.commit('SET_STEP', step);
});

export default router;
