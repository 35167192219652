/** @format */

import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import '@/assets/scss/index.scss';
import App from './App.vue';
import router from './router';
import store from './store';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
	location.replace('http://jyh.un-db.com/h5');
} else {
	const app = createApp(App);

	for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
		app.component(key, component);
	}

	app
		.use(ElementPlus, {
			locale: zhCn,
		})
		.use(store)
		.use(router)
		.mount('#app');
}
