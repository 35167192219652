/** @format */

import { createStore } from 'vuex';
import router from '../router';

export default createStore({
	state: {
		userMsg: { company: null, corporateor: null, orders: null, userBank: null, userCertificates: null, users: null, companyInvestigation: null },
		userId: null,
		step: 0,
	},
	mutations: {
		SET_USER_MSG(state, data) {
			state.userMsg = data;
		},
		SET_USER_ID(state, id) {
			state.userId = id;
		},
		SET_USERS(state, obj) {
			state.userMsg.users = obj;
		},
		// 设置步骤条进度
		SET_STEP(state, step) {
			state.step = step;
		},
		// 退出登录
		LOGIN_OUT(state) {
			state.step = 0;
			state.userId = null;
			state.userMsg = { company: null, corporateor: null, orders: null, userBank: null, userCertificates: null, users: null, companyInvestigation: null };
			router.push({
				name: 'apply',
				replace: true,
			});
		},
	},
	actions: {},
	modules: {},
});
